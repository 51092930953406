export const autenticationURL = (LMS, institutionCode) => {
  const getInstitutionData = (code) => {
    switch (code) {
      case 'CMVC':
        return {
          domain: 'compumed',
          clientID: process.env.REACT_APP_CLIENTID_CMVC_CANVAS,
        };
      case 'CMVC_moodle':
        return {
          domain: 'amhealthinstitute', // CAMBIAR
          clientID: process.env.REACT_APP_CLIENTID_CMVC_MOODLE, // CAMBIAR
        };
      case 'AMHEALTH':
        return {
          domain: 'amhealthinstitute',
          clientID: process.env.REACT_APP_CLIENTID_AMHEALTH,
        };
      case 'GAPAT':
        return {
          domain: 'gapat',
          clientID: process.env.REACT_APP_CLIENTID_GAPAT,
        };
      case 'UNAD':
        return {
          domain: 'unad',
          clientID: process.env.REACT_APP_CLIENTID_UNAD,
        };
      case 'SAU':
        return {
          domain: 'classroom.sabal.university',
          clientID: process.env.REACT_APP_CLIENTID_SAU,
        };
      case 'FGU':
        return {
          domain: 'virtual.floridaglobal.university',
          clientID: process.env.REACT_APP_CLIENTID_FGU,
        };
      case 'ABC':
        return {
          domain: 'virtual.americanbusiness',
          clientID: process.env.REACT_APP_CLIENTID_ABC,
        };
      case 'CBT':
        return {
          domain: 'cbt',
          clientID: process.env.REACT_APP_CLIENTID_CBT,
        };
      case 'SABER':
        return {
          domain: 'sabercollege',
          clientID: process.env.REACT_APP_CLIENTID_SABER,
        };
      default:
        return {
          domain: 'compumed',
          clientID: process.env.REACT_APP_CLIENTID_CMVC_CANVAS,
        };
    }
  };

  if (LMS === 'moodle') {
    if (institutionCode === 'CMVC') {
      return `https://moodle.${
        getInstitutionData(institutionCode.concat('_moodle')).domain
      }.com/local/oauth/login.php?client_id=${
        getInstitutionData(institutionCode.concat('_moodle')).clientID
      }&response_type=code`;
    } else if (institutionCode === 'GAPAT') {
      return `https://gapat.net/Mood/local/oauth/login.php?client_id=${
        getInstitutionData(institutionCode).clientID
      }&response_type=code`;
    } else if (institutionCode === 'UNAD') {
      return `https://${
        getInstitutionData(institutionCode).domain
      }.us/Repository2024/local/oauth/login.php?client_id=${
        getInstitutionData(institutionCode).clientID
      }&response_type=code`;
    } else if (institutionCode === 'SAU') {
      return `https://${
        getInstitutionData(institutionCode).domain
      }/local/oauth/login.php?client_id=${
        getInstitutionData(institutionCode).clientID
      }&response_type=code`;
    }else if (institutionCode === 'FGU') {
      return `https://${
        getInstitutionData(institutionCode).domain
      }/local/oauth/login.php?client_id=${
        getInstitutionData(institutionCode).clientID
      }&response_type=code`;
    } else if (institutionCode === 'ABC') {
      return `https://${
        getInstitutionData(institutionCode).domain
      }.college/local/oauth/login.php?client_id=${
        getInstitutionData(institutionCode).clientID
      }&response_type=code`;
    } else {
      return `https://moodle.${
        getInstitutionData(institutionCode).domain
      }.com/local/oauth/login.php?client_id=${
        getInstitutionData(institutionCode).clientID
      }&response_type=code`;
    }
  } else if (LMS === 'canvas') {
    return `https://${
      getInstitutionData(institutionCode).domain
    }.instructure.com/login/oauth2/auth?client_id=${
      getInstitutionData(institutionCode).clientID
    }&response_type=code&redirect_uri=https://olib.${
      getInstitutionData(institutionCode).domain
    }.edu/&scope=url:GET|/api/v1/users/:user_id/profile`;
  }
};
